import { Outlet } from 'react-router-dom';
import styled from '@emotion/styled';
import Contents from './contents/Contents';
import Sidebar from './sidebar/Sidebar';

export default function Layout() {
  return (
    <Main>
      <Sidebar />
      <Contents>
        <Outlet />
      </Contents>
    </Main>
  );
}

const Main = styled.main`
  display: flex;
`;
