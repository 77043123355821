import { css } from '@emotion/react';

export default css`
  :root {
    --white: #ffffff;
    --black: #000000;

    --primary: #3b82f6;
    --secondary: #9ca3af;
    --warning: #fb923c;
    --error: #dc2626;

    --gray-50: #f9fafb;
    --gray-100: #f3f4f6;
    --gray-200: #e5e7eb;
    --gray-300: #d1d5db;
    --gray-400: #9ca3af;
    --gray-500: #6b7280;
    --gray-600: #4b5563;
    --gray-700: #374151;
    --gray-800: #1f2937;
    --gray-900: #111827;
    --gray-950: #030712;

    --blue-50: #eff6ff;
    --blue-100: #dbeafe;
    --blue-200: #bfdbfe;
    --blue-300: #93c5fd;
    --blue-400: #60a5fa;
    --blue-500: #3b82f6;
    --blue-600: #2563eb;
    --blue-700: #1d4ed8;
    --blue-800: #1e40af;
    --blue-900: #1e3a8a;
    --blue-950: #172554;

    --input-height-large: 48px;
    --input-height-medium: 40px;
    --input-height-small: 32px;

    --input-radius-large: 6px;
    --input-radius-medium: 3px;
    --input-radius-small: 2px;

    --input-inset-padding-large: 12px;
    --input-inset-padding-medium: 8px;
    --input-inset-padding-small: 6px;

    --input-font-size-large: 16px;
    --input-font-size-medium: 16px;
    --input-font-size-small: 14px;

    --z-header: 1000;
    --z-sidebar: 2000;
    --z-dialog: 10000;

    --header-height: 52px;
    --footer-height: 52px;
    --sidebar-width: 280px;
  }
`;
