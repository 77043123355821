import { lazy } from 'react';
import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
} from 'react-router-dom';
import AuthLayout from 'src/components/layout/AuthLayout';
import Layout from 'src/components/layout/Layout';
import SuspenseLayout from 'src/components/layout/SuspenseLayout';
import { checkAuthToken, validateAuth } from 'src/utils/authRouterUtils';

const SignIn = lazy(() => import('src/pages/auth/SignIn'));

const CrawlingCreate = lazy(() => import('src/pages/crawlings/CrawlingCreate'));
const CrawlingDetail = lazy(() => import('src/pages/crawlings/CrawlingDetail'));
const CrawlingDetailHistory = lazy(() => import('src/pages/crawlings/CrawlingDetailHistory'));
const CrawlingDetailInfo = lazy(() => import('src/pages/crawlings/CrawlingDetailInfo'));
const CrawlingList = lazy(() => import('src/pages/crawlings/CrawlingList'));

const InstanceDetail = lazy(() => import('src/pages/instances/InstanceDetail'));
const InstanceList = lazy(() => import('src/pages/instances/InstanceList'));

const NotFound = lazy(() => import('src/pages/error/NotFound'));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<SuspenseLayout />}>
      {/* main */}
      <Route
        path="/"
        loader={validateAuth((isValid: boolean) => {
          return isValid ? '/crawlings' : '/auth/sign-in';
        })}
      />

      {/* auth */}
      <Route
        path="/auth/*"
        element={<AuthLayout />}
        loader={checkAuthToken((hasToken: boolean) => {
          return hasToken ? '/crawlings' : null;
        })}
      >
        <Route path="sign-in" element={<SignIn />} />
        <Route path="*" element={<Navigate to="/auth/sign-in" />} />
      </Route>

      {/* crawlings */}
      <Route
        path="/*"
        element={<Layout />}
        loader={validateAuth((isValid: boolean) => {
          return !isValid ? '/auth/sign-in' : null;
        })}
      >
        <Route path="crawlings" element={<CrawlingList />} />
        <Route path="crawlings/create" element={<CrawlingCreate />} />
        <Route element={<CrawlingDetail />}>
          <Route
            path="crawlings/:crawlingId"
            loader={({ params }) => redirect(`/crawlings/${params.crawlingId}/info`)}
          />
          <Route path="crawlings/:crawlingId/info" element={<CrawlingDetailInfo />} />
          <Route path="crawlings/:crawlingId/history" element={<CrawlingDetailHistory />} />
        </Route>

        <Route path="instances" element={<InstanceList />} />
        <Route path="instances/:instanceId" element={<InstanceDetail />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Route>,
  ),
);

export default router;
