import { css } from '@emotion/react';
import variables from './variables';
import override from './antDesignOverride';

export const globalStyles = css`
  ${variables}
  *,
  *::before,
  *::after {
    font-family: inherit;
    box-sizing: border-box;
    letter-spacing: 0;
    -webkit-font-smoothing: antialiased;
    overflow-anchor: none;
  }
  html {
    font-size: 16px;
  }
  body {
    margin: 0;
    padding: 0;
  }
  main {
    display: block;
  }
  label {
    font-weight: normal;
  }
  button,
  a {
    outline: none;
    color: inherit;
    text-decoration: none;
    &[disabled] {
      pointer-events: none !important;
    }
    cursor: pointer;
  }
  p,
  span,
  strong,
  small {
    color: inherit;
  }
  ul,
  ol,
  dl {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    outline: none;
  }
  p,
  dt,
  dd {
    margin: 0;
    padding: 0;
  }
  input,
  input:focus,
  input::before,
  input::after {
    outline: 0;
    touch-action: manipulation;
  }
  input:not([type='checkbox']):not([type='radio']),
  button,
  textarea,
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  textarea,
  textarea:focus {
    outline: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
  }

  ${override}
`;
