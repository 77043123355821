import { useMutation } from '@tanstack/react-query';
import httpClient from 'src/modules/http/httpClient';

async function signOutAPI() {
  return await httpClient.delete(`/api/v1/members`);
}

export default function useSignOut() {
  const mutation = useMutation({
    mutationFn: () => signOutAPI(),
    mutationKey: ['sign-out'],
  });

  return mutation;
}
