import styled from '@emotion/styled';
import { ReactNode } from 'react';
import ContentsHeader from './ContentsHeader';
import ContentsFooter from './ContentsFooter';

interface Props {
  children: ReactNode;
}

export default function Contents({ children }: Props) {
  return (
    <Container>
      <Inner>
        <ContentsHeader />
        <ContentsBody>{children}</ContentsBody>
        <ContentsFooter />
      </Inner>
    </Container>
  );
}

const Container = styled.article`
  width: calc(100% - var(--sidebar-width));
  height: 100vh;
  overflow: auto;
`;

const Inner = styled.div`
  min-width: 1000px;
`;

const ContentsBody = styled.div`
  min-height: calc(100vh - var(--header-height) - var(--footer-height));
`;
