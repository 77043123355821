import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { HddOutlined, ReconciliationOutlined } from '@ant-design/icons';
import LogoImage from 'src/assets/img/logo.png';

export default function Sidebar() {
  return (
    <Aside>
      <AsideHeader>
        <LogoBox>
          <Logo src={LogoImage} alt="로고" />
          <Title>S.F.Lab</Title>
        </LogoBox>
      </AsideHeader>
      <AsideMenu>
        <MenuItem>
          <NavLink to="/crawlings">
            <CrawlingIcon />
            <MenuTitle>크롤링 관리</MenuTitle>
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/instances">
            <InstanceIcon />
            <MenuTitle>인스턴스 관리</MenuTitle>
          </NavLink>
        </MenuItem>
      </AsideMenu>
    </Aside>
  );
}

const Aside = styled.aside`
  position: relative;
  flex-shrink: 0;
  width: var(--sidebar-width);
  height: 100vh;
  background-color: var(--white);
  box-shadow: rgba(33, 37, 41, 0.1) 1px 0px 4px 0px;
  z-index: var(--z-sidebar);
`;

const AsideHeader = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: 80px;
  border-bottom: 1px solid var(--gray-200);
  overflow-y: auto;
`;

const LogoBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 28px;
`;

const Logo = styled.img`
  height: 36px;
`;

const Title = styled.h2`
  line-height: 36px;
  margin-left: 16px;
`;

const AsideMenu = styled.div`
  padding: 12px 0;
`;

const MenuItem = styled.div`
  margin-bottom: 4px;

  &:hover {
    background-color: var(--gray-100);
  }

  a {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 16px 30px;
    color: var(--black);

    &.active {
      background-color: var(--gray-100);
      font-weight: 700;
    }
  }
`;

const MenuTitle = styled.span`
  margin-left: 10px;
  font-size: 15px;
`;

const CrawlingIcon = styled(ReconciliationOutlined)`
  font-size: 24px;
`;

const InstanceIcon = styled(HddOutlined)`
  font-size: 24px;
`;
