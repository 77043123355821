import { useQuery } from '@tanstack/react-query';
import httpClient from 'src/modules/http/httpClient';

import type { Response } from 'src/modules/http';

export interface UserDataResponse {
  id: string;
  name: string;
}

async function userDataAPI() {
  return (await httpClient.get<Response<UserDataResponse>>(`/api/v1/members/_me`)).data;
}

export default function useUserData() {
  const { data, isError, isLoading, refetch } = useQuery({
    queryKey: ['useUserData'],
    queryFn: () => userDataAPI(),
  });

  return {
    userData: data,
    refetch,
    isLoading,
    isError,
  };
}
