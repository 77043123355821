import Cookies from 'js-cookie';
import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { Response } from 'src/modules/http';
import httpClient from 'src/modules/http/httpClient';

const AuthTokenCookieKey = 'USER';

type CheckUserResponse = boolean;

export async function checkUserAPI() {
  return (await httpClient.get<Response<CheckUserResponse>>(`/api/v1/members/_check`)).data.content;
}

export function getAuthToken() {
  return Cookies.get(AuthTokenCookieKey);
}


type ValidateAuthCallback = (isValid: boolean, loaderArg: LoaderFunctionArgs) => string | null;

export function validateAuth(callback: ValidateAuthCallback) {
  return async (loaderArg: LoaderFunctionArgs) => {
    
    try {
      const user = await checkUserAPI();
      const redirectTarget = callback?.(Boolean(user), loaderArg) ?? null;

      if (redirectTarget) {
        return redirect(redirectTarget);
      }

      return null;
    } catch (e) {
      console.error(e);
      Cookies.remove(AuthTokenCookieKey);

      return redirect('/auth/sign-in');
    }
  };
}

export function checkAuthToken(callback: ValidateAuthCallback) {
  return async (loaderArg: LoaderFunctionArgs) => {
    const authToken = getAuthToken();
    const redirectTarget = callback?.(Boolean(authToken), loaderArg) ?? null;

    if (redirectTarget) {
      return redirect(redirectTarget);
    }

    return null;
  };
}
