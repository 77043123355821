import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Loading from 'src/components/ui/utils/Loading';

export default function SuspenseLayout() {
  return (
    <Suspense fallback={<Loading />}>
      <Outlet />
    </Suspense>
  );
}
