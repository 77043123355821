import styled from '@emotion/styled';

function Loading() {
  return <Container />;
}

const Container = styled.div`
  height: 100vh;
`;

export default Loading;
