import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { DownOutlined } from '@ant-design/icons';
import Dropdown from 'antd/es/dropdown/dropdown';
import HSpacer from 'src/components/ui/utils/HSpacer';
import useSignOut from 'src/hooks/auth/useSignOut';
import useUserData from 'src/hooks/auth/useUserData';

import type { MenuProps } from 'antd/es/menu/menu';

interface HeaderDropdownParams {
  key: string;
}

const items: MenuProps['items'] = [
  {
    label: '로그아웃',
    key: 'sign-out',
  },
];

export default function ContentsHeader() {
  const navigate = useNavigate();
  const { userData, isError, isLoading } = useUserData();
  const signOutMutation = useSignOut();

  const userName = useMemo(() => {
    if (isLoading || isError) {
      return '관리자';
    }

    const { name } = userData?.content ?? {};
    return name ?? '관리자';
  }, [isError, isLoading, userData]);
  const handleClick = async ({ key }: HeaderDropdownParams) => {
    if (key === 'sign-out') {
      try {
        await signOutMutation.mutateAsync();
        navigate('/auth/sign-in', { replace: true });
      } catch (e) {
        alert((e as Error).message);
      }
    }
  };

  return (
    <Container>
      <Inner>
        <Dropdown
          menu={{ items, onClick: handleClick }}
          trigger={['click']}
          placement="bottomRight"
          overlayStyle={{ width: 120 }}
        >
          <Profile>
            <span>{userName}</span>
            <HSpacer space={10} />
            <ProfileArrowIcon />
          </Profile>
        </Dropdown>
      </Inner>
    </Container>
  );
}

const Container = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--white);
`;

const Inner = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: var(--header-height);
  padding: 0 28px;
`;

const Profile = styled.div`
  display: flex;
  cursor: pointer;
`;

const ProfileArrowIcon = styled(DownOutlined)`
  font-size: 12px;
`;
