import { Global } from '@emotion/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import ConfigProvider from 'antd/es/config-provider';
import { globalStyles } from 'src/styles/globalStyles';
import ReactQueryProvider from 'src/providers/ReactQueryProvider';
import router from 'src/routes/Router';
import { token, components } from 'src/styles/antDesignTheme';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ReactQueryProvider>
      <ConfigProvider theme={{ hashed: false, token, components }}>
        <Global styles={[globalStyles]} />
        <RouterProvider router={router} />
      </ConfigProvider>
    </ReactQueryProvider>
  </React.StrictMode>,
);
