import styled from '@emotion/styled';

interface Props {
  space?: number;
}

export default function HSpacer({ space = 0 }: Props) {
  return <Space space={space} />;
}

const Space = styled.div<{ space: number }>`
  height: 100%;
  margin-left: ${({ space }) => `${space}px`};
`;
