import type { OverrideToken } from 'antd/es/theme/interface';
import type { AliasToken } from 'antd/es/theme/interface/alias';

/**
 * Antd Modifying Global Style API
 * @see {@link https://ant.design/docs/react/customize-theme#api}
 */
export const token: Partial<AliasToken> = {
  borderRadius: 4,
  controlHeightLG: 48,
  controlHeight: 40,
  controlHeightSM: 32,
  fontFamily: `-apple-system, BlinkMacSystemFont, system-ui,
      Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic',
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif`,
  zIndexPopupBase: 10000,
};

/**
 * @see {@link https://ant.design/components/overview}
 */
export const components: OverrideToken = {};
