import styled from '@emotion/styled';

export default function ContentsFooter() {
  return <Footer>Copyright 2023. (주)에스에프랩 All Rights Reserved.</Footer>;
}

const Footer = styled.footer`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0 24px;
  line-height: 12px;
  font-size: 12px;
  color: var(--gray-500);
`;
