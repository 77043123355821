import axios, { AxiosError, AxiosResponse } from 'axios';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API,
  timeout: 300000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

const onFulfilled = (response: AxiosResponse) => {
  const { result, message } = response?.data || {};

  if (!result) {
    console.error(response.data);

    return Promise.reject(new Error(message));
  }

  return response;
};

const onReject = (error: AxiosError) => {
  return Promise.reject(error);
};

httpClient.interceptors.response.use(onFulfilled, onReject);

export default httpClient;
